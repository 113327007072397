import React, { useState, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
 

const ApexChart = () => {
    const marketStart = new Date().setHours(9, 15, 0, 0);
    const marketEnd = new Date().setHours(15, 30, 0, 0);

    let data =
[
    {
        "open": 52886.15,
        "close": 52887.05,
        "high": 52905,
        "low": 52886,
        "date": "2024-09-18T06:56:00.000Z",
        "date2": "2024-09-18 12:26"
    },
    {
        "open": 52895.2,
        "close": 52880.25,
        "high": 52895.2,
        "low": 52880.25,
        "date": "2024-09-18T06:57:00.000Z",
        "date2": "2024-09-18 12:27"
    },
    {
        "open": 52884.85,
        "close": 52887,
        "high": 52894.9,
        "low": 52875.2,
        "date": "2024-09-18T06:58:00.000Z",
        "date2": "2024-09-18 12:28"
    },
    {
        "open": 52880,
        "close": 52867,
        "high": 52890.6,
        "low": 52867,
        "date": "2024-09-18T06:59:00.000Z",
        "date2": "2024-09-18 12:29"
    },
    {
        "open": 52870,
        "close": 52881.1,
        "high": 52886,
        "low": 52865.55,
        "date": "2024-09-18T07:00:00.000Z",
        "date2": "2024-09-18 12:30"
    },
    {
        "open": 52881.05,
        "close": 52882.95,
        "high": 52893.4,
        "low": 52881.05,
        "date": "2024-09-18T07:01:00.000Z",
        "date2": "2024-09-18 12:31"
    },
    {
        "open": 52889.45,
        "close": 52887,
        "high": 52893.95,
        "low": 52880,
        "date": "2024-09-18T07:02:00.000Z",
        "date2": "2024-09-18 12:32"
    },
    {
        "open": 52882,
        "close": 52890,
        "high": 52900,
        "low": 52880.2,
        "date": "2024-09-18T07:03:00.000Z",
        "date2": "2024-09-18 12:33"
    },
    {
        "open": 52887.05,
        "close": 52899.05,
        "high": 52899.95,
        "low": 52884.85,
        "date": "2024-09-18T07:04:00.000Z",
        "date2": "2024-09-18 12:34"
    },
    {
        "open": 52899.95,
        "close": 52908,
        "high": 52910,
        "low": 52893.15,
        "date": "2024-09-18T07:05:00.000Z",
        "date2": "2024-09-18 12:35"
    },
    {
        "open": 52902.6,
        "close": 52921.45,
        "high": 52930,
        "low": 52902.6,
        "date": "2024-09-18T07:06:00.000Z",
        "date2": "2024-09-18 12:36"
    },
    {
        "open": 52929,
        "close": 52935.75,
        "high": 52940,
        "low": 52925,
        "date": "2024-09-18T07:07:00.000Z",
        "date2": "2024-09-18 12:37"
    },
    {
        "open": 52929.4,
        "close": 52930,
        "high": 52940,
        "low": 52925.85,
        "date": "2024-09-18T07:08:00.000Z",
        "date2": "2024-09-18 12:38"
    },
    {
        "open": 52929.9,
        "close": 52928,
        "high": 52936,
        "low": 52924.85,
        "date": "2024-09-18T07:09:00.000Z",
        "date2": "2024-09-18 12:39"
    },
    {
        "open": 52930,
        "close": 52935.95,
        "high": 52936,
        "low": 52925.15,
        "date": "2024-09-18T07:10:00.000Z",
        "date2": "2024-09-18 12:40"
    },
    {
        "open": 52925.8,
        "close": 52942.55,
        "high": 52944.75,
        "low": 52925.8,
        "date": "2024-09-18T07:11:00.000Z",
        "date2": "2024-09-18 12:41"
    },
    {
        "open": 52940,
        "close": 52957.85,
        "high": 52965,
        "low": 52936.1,
        "date": "2024-09-18T07:12:00.000Z",
        "date2": "2024-09-18 12:42"
    },
    {
        "open": 52967.8,
        "close": 52970,
        "high": 52975,
        "low": 52951.2,
        "date": "2024-09-18T07:13:00.000Z",
        "date2": "2024-09-18 12:43"
    },
    {
        "open": 52973.55,
        "close": 52983.8,
        "high": 52987,
        "low": 52970,
        "date": "2024-09-18T07:14:00.000Z",
        "date2": "2024-09-18 12:44"
    },
    {
        "open": 52987,
        "close": 52987.25,
        "high": 52998.7,
        "low": 52982.2,
        "date": "2024-09-18T07:15:00.000Z",
        "date2": "2024-09-18 12:45"
    },
    {
        "open": 52996.3,
        "close": 52999,
        "high": 52999,
        "low": 52985.35,
        "date": "2024-09-18T07:16:00.000Z",
        "date2": "2024-09-18 12:46"
    },
    {
        "open": 52995.45,
        "close": 52990,
        "high": 52997.55,
        "low": 52988.7,
        "date": "2024-09-18T07:17:00.000Z",
        "date2": "2024-09-18 12:47"
    },
    {
        "open": 52992.7,
        "close": 52945.35,
        "high": 52992.75,
        "low": 52941.75,
        "date": "2024-09-18T07:18:00.000Z",
        "date2": "2024-09-18 12:48"
    },
    {
        "open": 52953.45,
        "close": 52916.5,
        "high": 52953.45,
        "low": 52916.5,
        "date": "2024-09-18T07:19:00.000Z",
        "date2": "2024-09-18 12:49"
    },
    {
        "open": 52915,
        "close": 52899.05,
        "high": 52921.9,
        "low": 52895.05,
        "date": "2024-09-18T07:20:00.000Z",
        "date2": "2024-09-18 12:50"
    },
    {
        "open": 52902,
        "close": 52923.85,
        "high": 52929.25,
        "low": 52892.05,
        "date": "2024-09-18T07:21:00.000Z",
        "date2": "2024-09-18 12:51"
    },
    {
        "open": 52910.4,
        "close": 52924.7,
        "high": 52924.7,
        "low": 52910.15,
        "date": "2024-09-18T07:22:00.000Z",
        "date2": "2024-09-18 12:52"
    },
    {
        "open": 52924.75,
        "close": 52924.65,
        "high": 52924.75,
        "low": 52907.7,
        "date": "2024-09-18T07:23:00.000Z",
        "date2": "2024-09-18 12:53"
    },
    {
        "open": 52924.45,
        "close": 52926.05,
        "high": 52932.85,
        "low": 52912.1,
        "date": "2024-09-18T07:24:00.000Z",
        "date2": "2024-09-18 12:54"
    },
    {
        "open": 52925.25,
        "close": 52920.2,
        "high": 52930,
        "low": 52914.85,
        "date": "2024-09-18T07:25:00.000Z",
        "date2": "2024-09-18 12:55"
    },
    {
        "open": 52918.7,
        "close": 52925,
        "high": 52930,
        "low": 52918.7,
        "date": "2024-09-18T07:26:00.000Z",
        "date2": "2024-09-18 12:56"
    },
    {
        "open": 52924.4,
        "close": 52925.75,
        "high": 52939,
        "low": 52924.1,
        "date": "2024-09-18T07:27:00.000Z",
        "date2": "2024-09-18 12:57"
    },
    {
        "open": 52925.5,
        "close": 52891.85,
        "high": 52930.8,
        "low": 52891.2,
        "date": "2024-09-18T07:28:00.000Z",
        "date2": "2024-09-18 12:58"
    },
    {
        "open": 52892,
        "close": 52866.7,
        "high": 52892.3,
        "low": 52861.4,
        "date": "2024-09-18T07:29:00.000Z",
        "date2": "2024-09-18 12:59"
    },
    {
        "open": 52868,
        "close": 52888.35,
        "high": 52889.95,
        "low": 52859.6,
        "date": "2024-09-18T07:30:00.000Z",
        "date2": "2024-09-18 13:00"
    },
    {
        "open": 52879.25,
        "close": 52878.85,
        "high": 52897.45,
        "low": 52874,
        "date": "2024-09-18T07:31:00.000Z",
        "date2": "2024-09-18 13:01"
    },
    {
        "open": 52873,
        "close": 52850.1,
        "high": 52880,
        "low": 52850.1,
        "date": "2024-09-18T07:32:00.000Z",
        "date2": "2024-09-18 13:02"
    },
    {
        "open": 52850.05,
        "close": 52807.5,
        "high": 52856.35,
        "low": 52802.6,
        "date": "2024-09-18T07:33:00.000Z",
        "date2": "2024-09-18 13:03"
    },
    {
        "open": 52809.95,
        "close": 52803.1,
        "high": 52815.9,
        "low": 52786.65,
        "date": "2024-09-18T07:34:00.000Z",
        "date2": "2024-09-18 13:04"
    },
    {
        "open": 52803.4,
        "close": 52790,
        "high": 52808.2,
        "low": 52790,
        "date": "2024-09-18T07:35:00.000Z",
        "date2": "2024-09-18 13:05"
    },
    {
        "open": 52794.45,
        "close": 52839.95,
        "high": 52840,
        "low": 52786,
        "date": "2024-09-18T07:36:00.000Z",
        "date2": "2024-09-18 13:06"
    },
    {
        "open": 52840,
        "close": 52875,
        "high": 52879,
        "low": 52837.25,
        "date": "2024-09-18T07:37:00.000Z",
        "date2": "2024-09-18 13:07"
    },
    {
        "open": 52875.75,
        "close": 52865.25,
        "high": 52882.75,
        "low": 52861.5,
        "date": "2024-09-18T07:38:00.000Z",
        "date2": "2024-09-18 13:08"
    },
    {
        "open": 52861.1,
        "close": 52876.05,
        "high": 52876.6,
        "low": 52856.1,
        "date": "2024-09-18T07:39:00.000Z",
        "date2": "2024-09-18 13:09"
    },
    {
        "open": 52865.8,
        "close": 52861.2,
        "high": 52879.95,
        "low": 52861,
        "date": "2024-09-18T07:40:00.000Z",
        "date2": "2024-09-18 13:10"
    },
    {
        "open": 52870.05,
        "close": 52843.55,
        "high": 52871.95,
        "low": 52839.45,
        "date": "2024-09-18T07:41:00.000Z",
        "date2": "2024-09-18 13:11"
    },
    {
        "open": 52850,
        "close": 52820,
        "high": 52850,
        "low": 52810,
        "date": "2024-09-18T07:42:00.000Z",
        "date2": "2024-09-18 13:12"
    },
    {
        "open": 52820.95,
        "close": 52843.95,
        "high": 52844.25,
        "low": 52816.25,
        "date": "2024-09-18T07:43:00.000Z",
        "date2": "2024-09-18 13:13"
    },
    {
        "open": 52842.7,
        "close": 52830,
        "high": 52845.75,
        "low": 52827.25,
        "date": "2024-09-18T07:44:00.000Z",
        "date2": "2024-09-18 13:14"
    },
    {
        "open": 52832.35,
        "close": 52824.25,
        "high": 52832.35,
        "low": 52815.05,
        "date": "2024-09-18T07:45:00.000Z",
        "date2": "2024-09-18 13:15"
    },
    {
        "open": 52829.3,
        "close": 52830.45,
        "high": 52832.6,
        "low": 52806.75,
        "date": "2024-09-18T07:46:00.000Z",
        "date2": "2024-09-18 13:16"
    },
    {
        "open": 52825.2,
        "close": 52832,
        "high": 52849,
        "low": 52822.8,
        "date": "2024-09-18T07:47:00.000Z",
        "date2": "2024-09-18 13:17"
    },
    {
        "open": 52833,
        "close": 52839.85,
        "high": 52845.45,
        "low": 52824.8,
        "date": "2024-09-18T07:48:00.000Z",
        "date2": "2024-09-18 13:18"
    },
    {
        "open": 52837.95,
        "close": 52833.45,
        "high": 52841.95,
        "low": 52830,
        "date": "2024-09-18T07:49:00.000Z",
        "date2": "2024-09-18 13:19"
    },
    {
        "open": 52830,
        "close": 52844.4,
        "high": 52844.9,
        "low": 52822.05,
        "date": "2024-09-18T07:50:00.000Z",
        "date2": "2024-09-18 13:20"
    },
    {
        "open": 52844.9,
        "close": 52821.05,
        "high": 52845.95,
        "low": 52821.05,
        "date": "2024-09-18T07:51:00.000Z",
        "date2": "2024-09-18 13:21"
    },
    {
        "open": 52829.9,
        "close": 52829.45,
        "high": 52839.4,
        "low": 52821.05,
        "date": "2024-09-18T07:52:00.000Z",
        "date2": "2024-09-18 13:22"
    },
    {
        "open": 52838.8,
        "close": 52830,
        "high": 52838.8,
        "low": 52815,
        "date": "2024-09-18T07:53:00.000Z",
        "date2": "2024-09-18 13:23"
    },
    {
        "open": 52834,
        "close": 52820.95,
        "high": 52838,
        "low": 52820.95,
        "date": "2024-09-18T07:54:00.000Z",
        "date2": "2024-09-18 13:24"
    },
    {
        "open": 52828.45,
        "close": 52817.4,
        "high": 52830.55,
        "low": 52810,
        "date": "2024-09-18T07:55:00.000Z",
        "date2": "2024-09-18 13:25"
    },
    {
        "open": 52818.75,
        "close": 52820.65,
        "high": 52829.4,
        "low": 52815,
        "date": "2024-09-18T07:56:00.000Z",
        "date2": "2024-09-18 13:26"
    },
    {
        "open": 52819.5,
        "close": 52834.1,
        "high": 52834.75,
        "low": 52815,
        "date": "2024-09-18T07:57:00.000Z",
        "date2": "2024-09-18 13:27"
    },
    {
        "open": 52833,
        "close": 52822.35,
        "high": 52833,
        "low": 52815.1,
        "date": "2024-09-18T07:58:00.000Z",
        "date2": "2024-09-18 13:28"
    },
    {
        "open": 52823.45,
        "close": 52801,
        "high": 52828.85,
        "low": 52801,
        "date": "2024-09-18T07:59:00.000Z",
        "date2": "2024-09-18 13:29"
    },
    {
        "open": 52805,
        "close": 52814.25,
        "high": 52819.95,
        "low": 52800.1,
        "date": "2024-09-18T08:00:00.000Z",
        "date2": "2024-09-18 13:30"
    },
    {
        "open": 52817.65,
        "close": 52793.65,
        "high": 52817.65,
        "low": 52793.65,
        "date": "2024-09-18T08:01:00.000Z",
        "date2": "2024-09-18 13:31"
    },
    {
        "open": 52799.95,
        "close": 52804.85,
        "high": 52809.95,
        "low": 52787.65,
        "date": "2024-09-18T08:02:00.000Z",
        "date2": "2024-09-18 13:32"
    },
    {
        "open": 52798,
        "close": 52781.55,
        "high": 52804,
        "low": 52772.35,
        "date": "2024-09-18T08:03:00.000Z",
        "date2": "2024-09-18 13:33"
    },
    {
        "open": 52787.95,
        "close": 52791.8,
        "high": 52809.95,
        "low": 52780.4,
        "date": "2024-09-18T08:04:00.000Z",
        "date2": "2024-09-18 13:34"
    },
    {
        "open": 52791.85,
        "close": 52743,
        "high": 52791.85,
        "low": 52743,
        "date": "2024-09-18T08:05:00.000Z",
        "date2": "2024-09-18 13:35"
    },
    {
        "open": 52748.6,
        "close": 52752.65,
        "high": 52752.65,
        "low": 52729.05,
        "date": "2024-09-18T08:06:00.000Z",
        "date2": "2024-09-18 13:36"
    },
    {
        "open": 52752.5,
        "close": 52745,
        "high": 52769.15,
        "low": 52740,
        "date": "2024-09-18T08:07:00.000Z",
        "date2": "2024-09-18 13:37"
    },
    {
        "open": 52745.6,
        "close": 52728,
        "high": 52749.65,
        "low": 52726.75,
        "date": "2024-09-18T08:08:00.000Z",
        "date2": "2024-09-18 13:38"
    },
    {
        "open": 52730.2,
        "close": 52744.95,
        "high": 52745.95,
        "low": 52722.8,
        "date": "2024-09-18T08:09:00.000Z",
        "date2": "2024-09-18 13:39"
    },
    {
        "open": 52745.75,
        "close": 52710,
        "high": 52746.4,
        "low": 52703.75,
        "date": "2024-09-18T08:10:00.000Z",
        "date2": "2024-09-18 13:40"
    },
    {
        "open": 52704.65,
        "close": 52743.15,
        "high": 52743.15,
        "low": 52701,
        "date": "2024-09-18T08:11:00.000Z",
        "date2": "2024-09-18 13:41"
    },
    {
        "open": 52746.9,
        "close": 52730,
        "high": 52746.9,
        "low": 52724.55,
        "date": "2024-09-18T08:12:00.000Z",
        "date2": "2024-09-18 13:42"
    },
    {
        "open": 52734.35,
        "close": 52730.05,
        "high": 52744.95,
        "low": 52729.3,
        "date": "2024-09-18T08:13:00.000Z",
        "date2": "2024-09-18 13:43"
    },
    {
        "open": 52737,
        "close": 52776.55,
        "high": 52780,
        "low": 52730,
        "date": "2024-09-18T08:14:00.000Z",
        "date2": "2024-09-18 13:44"
    },
    {
        "open": 52773.55,
        "close": 52802.6,
        "high": 52802.6,
        "low": 52760.05,
        "date": "2024-09-18T08:15:00.000Z",
        "date2": "2024-09-18 13:45"
    },
    {
        "open": 52800,
        "close": 52822.5,
        "high": 52823.65,
        "low": 52795.25,
        "date": "2024-09-18T08:16:00.000Z",
        "date2": "2024-09-18 13:46"
    },
    {
        "open": 52821.35,
        "close": 52813.55,
        "high": 52827.65,
        "low": 52810,
        "date": "2024-09-18T08:17:00.000Z",
        "date2": "2024-09-18 13:47"
    },
    {
        "open": 52810,
        "close": 52800,
        "high": 52814.45,
        "low": 52790.05,
        "date": "2024-09-18T08:18:00.000Z",
        "date2": "2024-09-18 13:48"
    },
    {
        "open": 52793,
        "close": 52800,
        "high": 52813.55,
        "low": 52793,
        "date": "2024-09-18T08:19:00.000Z",
        "date2": "2024-09-18 13:49"
    },
    {
        "open": 52807.1,
        "close": 52828,
        "high": 52828,
        "low": 52796.6,
        "date": "2024-09-18T08:20:00.000Z",
        "date2": "2024-09-18 13:50"
    },
    {
        "open": 52825,
        "close": 52854.15,
        "high": 52854.15,
        "low": 52825,
        "date": "2024-09-18T08:21:00.000Z",
        "date2": "2024-09-18 13:51"
    },
    {
        "open": 52854.95,
        "close": 52851.95,
        "high": 52857.05,
        "low": 52835,
        "date": "2024-09-18T08:22:00.000Z",
        "date2": "2024-09-18 13:52"
    },
    {
        "open": 52850.4,
        "close": 52850,
        "high": 52861.9,
        "low": 52841.6,
        "date": "2024-09-18T08:23:00.000Z",
        "date2": "2024-09-18 13:53"
    },
    {
        "open": 52852.2,
        "close": 52850,
        "high": 52859.95,
        "low": 52838,
        "date": "2024-09-18T08:24:00.000Z",
        "date2": "2024-09-18 13:54"
    },
    {
        "open": 52856.35,
        "close": 52861.55,
        "high": 52866,
        "low": 52838.1,
        "date": "2024-09-18T08:25:00.000Z",
        "date2": "2024-09-18 13:55"
    },
    {
        "open": 52858,
        "close": 52830.9,
        "high": 52862,
        "low": 52830.9,
        "date": "2024-09-18T08:26:00.000Z",
        "date2": "2024-09-18 13:56"
    },
    {
        "open": 52839.5,
        "close": 52847.2,
        "high": 52847.7,
        "low": 52830,
        "date": "2024-09-18T08:27:00.000Z",
        "date2": "2024-09-18 13:57"
    },
    {
        "open": 52840.6,
        "close": 52835.65,
        "high": 52851,
        "low": 52835.65,
        "date": "2024-09-18T08:28:00.000Z",
        "date2": "2024-09-18 13:58"
    },
    {
        "open": 52830,
        "close": 52840,
        "high": 52847,
        "low": 52830,
        "date": "2024-09-18T08:29:00.000Z",
        "date2": "2024-09-18 13:59"
    },
    {
        "open": 52843.65,
        "close": 52844.5,
        "high": 52847.05,
        "low": 52834.35,
        "date": "2024-09-18T08:30:00.000Z",
        "date2": "2024-09-18 14:00"
    },
    {
        "open": 52851.6,
        "close": 52811,
        "high": 52851.6,
        "low": 52811,
        "date": "2024-09-18T08:31:00.000Z",
        "date2": "2024-09-18 14:01"
    },
    {
        "open": 52810.3,
        "close": 52822.95,
        "high": 52823.4,
        "low": 52805,
        "date": "2024-09-18T08:32:00.000Z",
        "date2": "2024-09-18 14:02"
    },
    {
        "open": 52823.05,
        "close": 52838.65,
        "high": 52839.15,
        "low": 52823.05,
        "date": "2024-09-18T08:33:00.000Z",
        "date2": "2024-09-18 14:03"
    },
    {
        "open": 52834.45,
        "close": 52826.1,
        "high": 52835,
        "low": 52820,
        "date": "2024-09-18T08:34:00.000Z",
        "date2": "2024-09-18 14:04"
    },
    {
        "open": 52828,
        "close": 52834.95,
        "high": 52834.95,
        "low": 52826,
        "date": "2024-09-18T08:35:00.000Z",
        "date2": "2024-09-18 14:05"
    },
    {
        "open": 52835,
        "close": 52843.9,
        "high": 52843.9,
        "low": 52827,
        "date": "2024-09-18T08:36:00.000Z",
        "date2": "2024-09-18 14:06"
    },
    {
        "open": 52840.4,
        "close": 52849.95,
        "high": 52853.05,
        "low": 52836,
        "date": "2024-09-18T08:37:00.000Z",
        "date2": "2024-09-18 14:07"
    },
    {
        "open": 52850,
        "close": 52827,
        "high": 52850,
        "low": 52827,
        "date": "2024-09-18T08:38:00.000Z",
        "date2": "2024-09-18 14:08"
    },
    {
        "open": 52830.45,
        "close": 52822.1,
        "high": 52832.45,
        "low": 52815.45,
        "date": "2024-09-18T08:39:00.000Z",
        "date2": "2024-09-18 14:09"
    },
    {
        "open": 52822.15,
        "close": 52829,
        "high": 52833.85,
        "low": 52811.4,
        "date": "2024-09-18T08:40:00.000Z",
        "date2": "2024-09-18 14:10"
    },
    {
        "open": 52829.3,
        "close": 52841.85,
        "high": 52845,
        "low": 52826.85,
        "date": "2024-09-18T08:41:00.000Z",
        "date2": "2024-09-18 14:11"
    },
    {
        "open": 52837.75,
        "close": 52842.95,
        "high": 52844.95,
        "low": 52832.95,
        "date": "2024-09-18T08:42:00.000Z",
        "date2": "2024-09-18 14:12"
    },
    {
        "open": 52843.6,
        "close": 52842,
        "high": 52843.6,
        "low": 52832,
        "date": "2024-09-18T08:43:00.000Z",
        "date2": "2024-09-18 14:13"
    },
    {
        "open": 52841.2,
        "close": 52838.3,
        "high": 52849,
        "low": 52831.1,
        "date": "2024-09-18T08:44:00.000Z",
        "date2": "2024-09-18 14:14"
    },
    {
        "open": 52842.2,
        "close": 52827.25,
        "high": 52842.2,
        "low": 52820.1,
        "date": "2024-09-18T08:45:00.000Z",
        "date2": "2024-09-18 14:15"
    },
    {
        "open": 52823.55,
        "close": 52820,
        "high": 52827.05,
        "low": 52814.4,
        "date": "2024-09-18T08:46:00.000Z",
        "date2": "2024-09-18 14:16"
    },
    {
        "open": 52819,
        "close": 52849,
        "high": 52849,
        "low": 52816.3,
        "date": "2024-09-18T08:47:00.000Z",
        "date2": "2024-09-18 14:17"
    },
    {
        "open": 52848.95,
        "close": 52845.05,
        "high": 52848.95,
        "low": 52845,
        "date": "2024-09-18T08:48:00.000Z",
        "date2": "2024-09-18 14:18"
    },
    {
        "open": 52845.4,
        "close": 52848.85,
        "high": 52851,
        "low": 52845,
        "date": "2024-09-18T08:49:00.000Z",
        "date2": "2024-09-18 14:19"
    },
    {
        "open": 52846.05,
        "close": 52857.3,
        "high": 52859.35,
        "low": 52844.05,
        "date": "2024-09-18T08:50:00.000Z",
        "date2": "2024-09-18 14:20"
    },
    {
        "open": 52859,
        "close": 52863.2,
        "high": 52863.2,
        "low": 52853,
        "date": "2024-09-18T08:51:00.000Z",
        "date2": "2024-09-18 14:21"
    },
    {
        "open": 52866,
        "close": 52878.45,
        "high": 52887.95,
        "low": 52866,
        "date": "2024-09-18T08:52:00.000Z",
        "date2": "2024-09-18 14:22"
    },
    {
        "open": 52879.3,
        "close": 52877.65,
        "high": 52882.4,
        "low": 52867.15,
        "date": "2024-09-18T08:53:00.000Z",
        "date2": "2024-09-18 14:23"
    },
    {
        "open": 52877.7,
        "close": 52890,
        "high": 52896.7,
        "low": 52870.2,
        "date": "2024-09-18T08:54:00.000Z",
        "date2": "2024-09-18 14:24"
    },
    {
        "open": 52888,
        "close": 52882.8,
        "high": 52892.3,
        "low": 52875,
        "date": "2024-09-18T08:55:00.000Z",
        "date2": "2024-09-18 14:25"
    },
    {
        "open": 52882.85,
        "close": 52847.4,
        "high": 52883.05,
        "low": 52846.2,
        "date": "2024-09-18T08:56:00.000Z",
        "date2": "2024-09-18 14:26"
    },
    {
        "open": 52856.55,
        "close": 52836.25,
        "high": 52856.95,
        "low": 52836.25,
        "date": "2024-09-18T08:57:00.000Z",
        "date2": "2024-09-18 14:27"
    },
    {
        "open": 52841.55,
        "close": 52835.05,
        "high": 52844.3,
        "low": 52832.85,
        "date": "2024-09-18T08:58:00.000Z",
        "date2": "2024-09-18 14:28"
    },
    {
        "open": 52836.5,
        "close": 52820,
        "high": 52836.5,
        "low": 52820,
        "date": "2024-09-18T08:59:00.000Z",
        "date2": "2024-09-18 14:29"
    },
    {
        "open": 52818.05,
        "close": 52833.85,
        "high": 52840.6,
        "low": 52818.05,
        "date": "2024-09-18T09:00:00.000Z",
        "date2": "2024-09-18 14:30"
    },
    {
        "open": 52838.75,
        "close": 52844.6,
        "high": 52845,
        "low": 52832.05,
        "date": "2024-09-18T09:01:00.000Z",
        "date2": "2024-09-18 14:31"
    },
    {
        "open": 52838.1,
        "close": 52841.05,
        "high": 52847,
        "low": 52836.15,
        "date": "2024-09-18T09:02:00.000Z",
        "date2": "2024-09-18 14:32"
    },
    {
        "open": 52844.95,
        "close": 52840,
        "high": 52850,
        "low": 52835.15,
        "date": "2024-09-18T09:03:00.000Z",
        "date2": "2024-09-18 14:33"
    },
    {
        "open": 52834.75,
        "close": 52813.05,
        "high": 52842.75,
        "low": 52811,
        "date": "2024-09-18T09:04:00.000Z",
        "date2": "2024-09-18 14:34"
    },
    {
        "open": 52812.7,
        "close": 52830.25,
        "high": 52830.4,
        "low": 52811.1,
        "date": "2024-09-18T09:05:00.000Z",
        "date2": "2024-09-18 14:35"
    },
    {
        "open": 52832.9,
        "close": 52826.1,
        "high": 52842.5,
        "low": 52825,
        "date": "2024-09-18T09:06:00.000Z",
        "date2": "2024-09-18 14:36"
    },
    {
        "open": 52826.35,
        "close": 52816,
        "high": 52829.45,
        "low": 52815,
        "date": "2024-09-18T09:07:00.000Z",
        "date2": "2024-09-18 14:37"
    },
    {
        "open": 52827.45,
        "close": 52811.1,
        "high": 52834.55,
        "low": 52811,
        "date": "2024-09-18T09:08:00.000Z",
        "date2": "2024-09-18 14:38"
    },
    {
        "open": 52812.25,
        "close": 52819,
        "high": 52829.5,
        "low": 52805.8,
        "date": "2024-09-18T09:09:00.000Z",
        "date2": "2024-09-18 14:39"
    },
    {
        "open": 52815,
        "close": 52813.95,
        "high": 52825,
        "low": 52811.5,
        "date": "2024-09-18T09:10:00.000Z",
        "date2": "2024-09-18 14:40"
    },
    {
        "open": 52817.25,
        "close": 52826.3,
        "high": 52830.5,
        "low": 52813.6,
        "date": "2024-09-18T09:11:00.000Z",
        "date2": "2024-09-18 14:41"
    },
    {
        "open": 52827.5,
        "close": 52831.45,
        "high": 52833.3,
        "low": 52820.05,
        "date": "2024-09-18T09:12:00.000Z",
        "date2": "2024-09-18 14:42"
    },
    {
        "open": 52827.1,
        "close": 52818.35,
        "high": 52829.75,
        "low": 52818.35,
        "date": "2024-09-18T09:13:00.000Z",
        "date2": "2024-09-18 14:43"
    },
    {
        "open": 52821.5,
        "close": 52820.85,
        "high": 52823.45,
        "low": 52815.3,
        "date": "2024-09-18T09:14:00.000Z",
        "date2": "2024-09-18 14:44"
    },
    {
        "open": 52818.7,
        "close": 52820.5,
        "high": 52825.95,
        "low": 52811,
        "date": "2024-09-18T09:15:00.000Z",
        "date2": "2024-09-18 14:45"
    },
    {
        "open": 52813,
        "close": 52830.55,
        "high": 52832.4,
        "low": 52811,
        "date": "2024-09-18T09:16:00.000Z",
        "date2": "2024-09-18 14:46"
    },
    {
        "open": 52830.7,
        "close": 52830,
        "high": 52836.4,
        "low": 52822,
        "date": "2024-09-18T09:17:00.000Z",
        "date2": "2024-09-18 14:47"
    },
    {
        "open": 52832.8,
        "close": 52819.85,
        "high": 52832.8,
        "low": 52809.85,
        "date": "2024-09-18T09:18:00.000Z",
        "date2": "2024-09-18 14:48"
    },
    {
        "open": 52810.45,
        "close": 52816.05,
        "high": 52818.4,
        "low": 52805.3,
        "date": "2024-09-18T09:19:00.000Z",
        "date2": "2024-09-18 14:49"
    },
    {
        "open": 52805.4,
        "close": 52815.2,
        "high": 52815.2,
        "low": 52805,
        "date": "2024-09-18T09:20:00.000Z",
        "date2": "2024-09-18 14:50"
    },
    {
        "open": 52815.8,
        "close": 52811.45,
        "high": 52823.95,
        "low": 52810,
        "date": "2024-09-18T09:21:00.000Z",
        "date2": "2024-09-18 14:51"
    },
    {
        "open": 52820.2,
        "close": 52812.5,
        "high": 52824.95,
        "low": 52810,
        "date": "2024-09-18T09:22:00.000Z",
        "date2": "2024-09-18 14:52"
    },
    {
        "open": 52815,
        "close": 52819,
        "high": 52819,
        "low": 52810,
        "date": "2024-09-18T09:23:00.000Z",
        "date2": "2024-09-18 14:53"
    },
    {
        "open": 52816.85,
        "close": 52810,
        "high": 52819,
        "low": 52810,
        "date": "2024-09-18T09:24:00.000Z",
        "date2": "2024-09-18 14:54"
    },
    {
        "open": 52810.1,
        "close": 52810.1,
        "high": 52818.6,
        "low": 52810,
        "date": "2024-09-18T09:25:00.000Z",
        "date2": "2024-09-18 14:55"
    },
    {
        "open": 52810.5,
        "close": 52804,
        "high": 52813.4,
        "low": 52804,
        "date": "2024-09-18T09:26:00.000Z",
        "date2": "2024-09-18 14:56"
    },
    {
        "open": 52810,
        "close": 52806,
        "high": 52817.8,
        "low": 52803,
        "date": "2024-09-18T09:27:00.000Z",
        "date2": "2024-09-18 14:57"
    },
    {
        "open": 52808.75,
        "close": 52790,
        "high": 52810.75,
        "low": 52790,
        "date": "2024-09-18T09:28:00.000Z",
        "date2": "2024-09-18 14:58"
    },
    {
        "open": 52797.2,
        "close": 52787.1,
        "high": 52798.25,
        "low": 52784,
        "date": "2024-09-18T09:29:00.000Z",
        "date2": "2024-09-18 14:59"
    },
    {
        "open": 52787.4,
        "close": 52775.4,
        "high": 52795.95,
        "low": 52768.65,
        "date": "2024-09-18T09:30:00.000Z",
        "date2": "2024-09-18 15:00"
    },
    {
        "open": 52772.25,
        "close": 52791.65,
        "high": 52792.15,
        "low": 52763.25,
        "date": "2024-09-18T09:31:00.000Z",
        "date2": "2024-09-18 15:01"
    },
    {
        "open": 52786.05,
        "close": 52793.2,
        "high": 52822.65,
        "low": 52786.05,
        "date": "2024-09-18T09:32:00.000Z",
        "date2": "2024-09-18 15:02"
    },
    {
        "open": 52802.45,
        "close": 52793.5,
        "high": 52810.45,
        "low": 52788.95,
        "date": "2024-09-18T09:33:00.000Z",
        "date2": "2024-09-18 15:03"
    },
    {
        "open": 52806.1,
        "close": 52821.25,
        "high": 52822,
        "low": 52791.7,
        "date": "2024-09-18T09:34:00.000Z",
        "date2": "2024-09-18 15:04"
    },
    {
        "open": 52821.7,
        "close": 52826.5,
        "high": 52829,
        "low": 52811.2,
        "date": "2024-09-18T09:35:00.000Z",
        "date2": "2024-09-18 15:05"
    },
    {
        "open": 52822.85,
        "close": 52831.95,
        "high": 52832,
        "low": 52804.95,
        "date": "2024-09-18T09:36:00.000Z",
        "date2": "2024-09-18 15:06"
    },
    {
        "open": 52828.9,
        "close": 52850,
        "high": 52850,
        "low": 52828,
        "date": "2024-09-18T09:37:00.000Z",
        "date2": "2024-09-18 15:07"
    },
    {
        "open": 52848.9,
        "close": 52838.5,
        "high": 52864.65,
        "low": 52825.3,
        "date": "2024-09-18T09:38:00.000Z",
        "date2": "2024-09-18 15:08"
    },
    {
        "open": 52836.65,
        "close": 52836.8,
        "high": 52838.3,
        "low": 52820,
        "date": "2024-09-18T09:39:00.000Z",
        "date2": "2024-09-18 15:09"
    },
    {
        "open": 52827.95,
        "close": 52814.1,
        "high": 52838.8,
        "low": 52810,
        "date": "2024-09-18T09:40:00.000Z",
        "date2": "2024-09-18 15:10"
    },
    {
        "open": 52810,
        "close": 52794.85,
        "high": 52813.75,
        "low": 52790.85,
        "date": "2024-09-18T09:41:00.000Z",
        "date2": "2024-09-18 15:11"
    },
    {
        "open": 52796.05,
        "close": 52802.35,
        "high": 52814.1,
        "low": 52796.05,
        "date": "2024-09-18T09:42:00.000Z",
        "date2": "2024-09-18 15:12"
    },
    {
        "open": 52809.4,
        "close": 52796.6,
        "high": 52809.4,
        "low": 52794.6,
        "date": "2024-09-18T09:43:00.000Z",
        "date2": "2024-09-18 15:13"
    },
    {
        "open": 52804.2,
        "close": 52796.05,
        "high": 52804.6,
        "low": 52787.35,
        "date": "2024-09-18T09:44:00.000Z",
        "date2": "2024-09-18 15:14"
    },
    {
        "open": 52796.5,
        "close": 52791.75,
        "high": 52808,
        "low": 52780.4,
        "date": "2024-09-18T09:45:00.000Z",
        "date2": "2024-09-18 15:15"
    },
    {
        "open": 52792.6,
        "close": 52775,
        "high": 52796.5,
        "low": 52775,
        "date": "2024-09-18T09:46:00.000Z",
        "date2": "2024-09-18 15:16"
    },
    {
        "open": 52775.95,
        "close": 52755.6,
        "high": 52778.7,
        "low": 52755.6,
        "date": "2024-09-18T09:47:00.000Z",
        "date2": "2024-09-18 15:17"
    },
    {
        "open": 52758.7,
        "close": 52741.55,
        "high": 52763.5,
        "low": 52740.75,
        "date": "2024-09-18T09:48:00.000Z",
        "date2": "2024-09-18 15:18"
    },
    {
        "open": 52741.15,
        "close": 52710.95,
        "high": 52741.8,
        "low": 52710,
        "date": "2024-09-18T09:49:00.000Z",
        "date2": "2024-09-18 15:19"
    },
    {
        "open": 52707.9,
        "close": 52715,
        "high": 52735,
        "low": 52707.9,
        "date": "2024-09-18T09:50:00.000Z",
        "date2": "2024-09-18 15:20"
    },
    {
        "open": 52711.3,
        "close": 52728.8,
        "high": 52729.95,
        "low": 52710,
        "date": "2024-09-18T09:51:00.000Z",
        "date2": "2024-09-18 15:21"
    },
    {
        "open": 52720,
        "close": 52720.45,
        "high": 52727.7,
        "low": 52720,
        "date": "2024-09-18T09:52:00.000Z",
        "date2": "2024-09-18 15:22"
    },
    {
        "open": 52720.05,
        "close": 52717.5,
        "high": 52722.3,
        "low": 52715,
        "date": "2024-09-18T09:53:00.000Z",
        "date2": "2024-09-18 15:23"
    },
    {
        "open": 52715,
        "close": 52716.2,
        "high": 52720,
        "low": 52710,
        "date": "2024-09-18T09:54:00.000Z",
        "date2": "2024-09-18 15:24"
    },
    {
        "open": 52716.1,
        "close": 52714.25,
        "high": 52716.65,
        "low": 52712.55,
        "date": "2024-09-18T09:55:00.000Z",
        "date2": "2024-09-18 15:25"
    },
    {
        "open": 52714.3,
        "close": 52700,
        "high": 52716.3,
        "low": 52688.9,
        "date": "2024-09-18T09:56:00.000Z",
        "date2": "2024-09-18 15:26"
    },
    {
        "open": 52690.05,
        "close": 52705,
        "high": 52705,
        "low": 52686.05,
        "date": "2024-09-18T09:57:00.000Z",
        "date2": "2024-09-18 15:27"
    },
    {
        "open": 52700,
        "close": 52707.15,
        "high": 52710,
        "low": 52693.4,
        "date": "2024-09-18T09:58:00.000Z",
        "date2": "2024-09-18 15:28"
    },
    {
        "open": 52706.95,
        "close": 52700,
        "high": 52719.8,
        "low": 52698.25,
        "date": "2024-09-18T09:59:00.000Z",
        "date2": "2024-09-18 15:29"
    },
    {
        "open": 52718.9,
        "close": 52718.9,
        "high": 52718.9,
        "low": 52718.9,
        "date": "2024-09-18T10:00:00.000Z",
        "date2": "2024-09-18 15:30"
    },
    {
        "open": 53113.85,
        "close": 53168.3,
        "high": 53168.7,
        "low": 53094.7,
        "date": "2024-09-19T03:45:00.000Z",
        "date2": "2024-09-19 09:15"
    },
    {
        "open": 53171.95,
        "close": 53178.95,
        "high": 53190,
        "low": 53143.2,
        "date": "2024-09-19T03:46:00.000Z",
        "date2": "2024-09-19 09:16"
    },
    {
        "open": 53170,
        "close": 53209.9,
        "high": 53216.6,
        "low": 53170,
        "date": "2024-09-19T03:47:00.000Z",
        "date2": "2024-09-19 09:17"
    },
    {
        "open": 53210,
        "close": 53226.6,
        "high": 53235.3,
        "low": 53190,
        "date": "2024-09-19T03:48:00.000Z",
        "date2": "2024-09-19 09:18"
    },
    {
        "open": 53239.45,
        "close": 53270.2,
        "high": 53280,
        "low": 53225.2,
        "date": "2024-09-19T03:49:00.000Z",
        "date2": "2024-09-19 09:19"
    },
    {
        "open": 53271,
        "close": 53410.25,
        "high": 53420.85,
        "low": 53265,
        "date": "2024-09-19T03:50:00.000Z",
        "date2": "2024-09-19 09:20"
    },
    {
        "open": 53443.3,
        "close": 53387.4,
        "high": 53444.1,
        "low": 53354.2,
        "date": "2024-09-19T03:51:00.000Z",
        "date2": "2024-09-19 09:21"
    },
    {
        "open": 53370.75,
        "close": 53327.35,
        "high": 53380,
        "low": 53317,
        "date": "2024-09-19T03:52:00.000Z",
        "date2": "2024-09-19 09:22"
    },
    {
        "open": 53322,
        "close": 53239.95,
        "high": 53322,
        "low": 53206.5,
        "date": "2024-09-19T03:53:00.000Z",
        "date2": "2024-09-19 09:23"
    },
    {
        "open": 53245,
        "close": 53256.1,
        "high": 53287.9,
        "low": 53239.75,
        "date": "2024-09-19T03:54:00.000Z",
        "date2": "2024-09-19 09:24"
    },
    {
        "open": 53252,
        "close": 53255.5,
        "high": 53260,
        "low": 53225,
        "date": "2024-09-19T03:55:00.000Z",
        "date2": "2024-09-19 09:25"
    },
    {
        "open": 53258.15,
        "close": 53343.15,
        "high": 53344.85,
        "low": 53253.45,
        "date": "2024-09-19T03:56:00.000Z",
        "date2": "2024-09-19 09:26"
    },
    {
        "open": 53349.05,
        "close": 53312.65,
        "high": 53358,
        "low": 53312.1,
        "date": "2024-09-19T03:57:00.000Z",
        "date2": "2024-09-19 09:27"
    },
    {
        "open": 53313.8,
        "close": 53274.2,
        "high": 53327.75,
        "low": 53268.05,
        "date": "2024-09-19T03:58:00.000Z",
        "date2": "2024-09-19 09:28"
    },
    {
        "open": 53281.15,
        "close": 53232.55,
        "high": 53284,
        "low": 53221,
        "date": "2024-09-19T03:59:00.000Z",
        "date2": "2024-09-19 09:29"
    },
    {
        "open": 53252,
        "close": 53208.6,
        "high": 53252,
        "low": 53192.3,
        "date": "2024-09-19T04:00:00.000Z",
        "date2": "2024-09-19 09:30"
    },
    {
        "open": 53213.95,
        "close": 53225.45,
        "high": 53236.5,
        "low": 53190,
        "date": "2024-09-19T04:01:00.000Z",
        "date2": "2024-09-19 09:31"
    },
    {
        "open": 53225.6,
        "close": 53240.6,
        "high": 53249,
        "low": 53197.4,
        "date": "2024-09-19T04:02:00.000Z",
        "date2": "2024-09-19 09:32"
    },
    {
        "open": 53236.85,
        "close": 53214.05,
        "high": 53250,
        "low": 53210,
        "date": "2024-09-19T04:03:00.000Z",
        "date2": "2024-09-19 09:33"
    },
    {
        "open": 53216.3,
        "close": 53209.45,
        "high": 53230,
        "low": 53195.5,
        "date": "2024-09-19T04:04:00.000Z",
        "date2": "2024-09-19 09:34"
    },
    {
        "open": 53211.75,
        "close": 53281.8,
        "high": 53283.6,
        "low": 53211,
        "date": "2024-09-19T04:05:00.000Z",
        "date2": "2024-09-19 09:35"
    },
    {
        "open": 53283.15,
        "close": 53303.05,
        "high": 53307.4,
        "low": 53265.05,
        "date": "2024-09-19T04:06:00.000Z",
        "date2": "2024-09-19 09:36"
    },
    {
        "open": 53295.9,
        "close": 53315.45,
        "high": 53322.95,
        "low": 53277.7,
        "date": "2024-09-19T04:07:00.000Z",
        "date2": "2024-09-19 09:37"
    },
    {
        "open": 53313.35,
        "close": 53271.35,
        "high": 53315,
        "low": 53261.75,
        "date": "2024-09-19T04:08:00.000Z",
        "date2": "2024-09-19 09:38"
    },
    {
        "open": 53265,
        "close": 53261.75,
        "high": 53297.05,
        "low": 53253.1,
        "date": "2024-09-19T04:09:00.000Z",
        "date2": "2024-09-19 09:39"
    },
    {
        "open": 53261.85,
        "close": 53290,
        "high": 53290,
        "low": 53225,
        "date": "2024-09-19T04:10:00.000Z",
        "date2": "2024-09-19 09:40"
    },
    {
        "open": 53297,
        "close": 53260,
        "high": 53305.8,
        "low": 53260,
        "date": "2024-09-19T04:11:00.000Z",
        "date2": "2024-09-19 09:41"
    },
    {
        "open": 53266.8,
        "close": 53274,
        "high": 53280,
        "low": 53251.5,
        "date": "2024-09-19T04:12:00.000Z",
        "date2": "2024-09-19 09:42"
    },
    {
        "open": 53272.25,
        "close": 53307.1,
        "high": 53307.1,
        "low": 53270.15,
        "date": "2024-09-19T04:13:00.000Z",
        "date2": "2024-09-19 09:43"
    },
    {
        "open": 53306.9,
        "close": 53323.6,
        "high": 53330,
        "low": 53295.1,
        "date": "2024-09-19T04:14:00.000Z",
        "date2": "2024-09-19 09:44"
    },
    {
        "open": 53323,
        "close": 53360.1,
        "high": 53360.1,
        "low": 53314.6,
        "date": "2024-09-19T04:15:00.000Z",
        "date2": "2024-09-19 09:45"
    },
    {
        "open": 53360,
        "close": 53295.2,
        "high": 53360,
        "low": 53294.9,
        "date": "2024-09-19T04:16:00.000Z",
        "date2": "2024-09-19 09:46"
    },
    {
        "open": 53296.9,
        "close": 53286.4,
        "high": 53311.8,
        "low": 53277,
        "date": "2024-09-19T04:17:00.000Z",
        "date2": "2024-09-19 09:47"
    },
    {
        "open": 53287.05,
        "close": 53270,
        "high": 53293.05,
        "low": 53266,
        "date": "2024-09-19T04:18:00.000Z",
        "date2": "2024-09-19 09:48"
    },
    {
        "open": 53269,
        "close": 53232.7,
        "high": 53275,
        "low": 53229.05,
        "date": "2024-09-19T04:19:00.000Z",
        "date2": "2024-09-19 09:49"
    },
    {
        "open": 53240.05,
        "close": 53216,
        "high": 53262.95,
        "low": 53216,
        "date": "2024-09-19T04:20:00.000Z",
        "date2": "2024-09-19 09:50"
    },
    {
        "open": 53206.45,
        "close": 53184.6,
        "high": 53221.45,
        "low": 53171.7,
        "date": "2024-09-19T04:21:00.000Z",
        "date2": "2024-09-19 09:51"
    },
    {
        "open": 53183.75,
        "close": 53153.4,
        "high": 53197.25,
        "low": 53140,
        "date": "2024-09-19T04:22:00.000Z",
        "date2": "2024-09-19 09:52"
    },
    {
        "open": 53150.7,
        "close": 53071.1,
        "high": 53157,
        "low": 53035.8,
        "date": "2024-09-19T04:23:00.000Z",
        "date2": "2024-09-19 09:53"
    },
    {
        "open": 53057.2,
        "close": 53085.3,
        "high": 53125,
        "low": 53057.2,
        "date": "2024-09-19T04:24:00.000Z",
        "date2": "2024-09-19 09:54"
    },
    {
        "open": 53089,
        "close": 53131.05,
        "high": 53143.5,
        "low": 53079.55,
        "date": "2024-09-19T04:25:00.000Z",
        "date2": "2024-09-19 09:55"
    },
    {
        "open": 53141.9,
        "close": 53157.75,
        "high": 53172.75,
        "low": 53116.75,
        "date": "2024-09-19T04:26:00.000Z",
        "date2": "2024-09-19 09:56"
    },
    {
        "open": 53159.15,
        "close": 53162,
        "high": 53174.75,
        "low": 53144,
        "date": "2024-09-19T04:27:00.000Z",
        "date2": "2024-09-19 09:57"
    },
    {
        "open": 53165.6,
        "close": 53148.9,
        "high": 53165.6,
        "low": 53110,
        "date": "2024-09-19T04:28:00.000Z",
        "date2": "2024-09-19 09:58"
    },
    {
        "open": 53148.95,
        "close": 53120.75,
        "high": 53150.55,
        "low": 53120,
        "date": "2024-09-19T04:29:00.000Z",
        "date2": "2024-09-19 09:59"
    },
    {
        "open": 53120.15,
        "close": 53170,
        "high": 53172.7,
        "low": 53115,
        "date": "2024-09-19T04:30:00.000Z",
        "date2": "2024-09-19 10:00"
    },
    {
        "open": 53173,
        "close": 53185,
        "high": 53200,
        "low": 53173,
        "date": "2024-09-19T04:31:00.000Z",
        "date2": "2024-09-19 10:01"
    },
    {
        "open": 53185.7,
        "close": 53203.1,
        "high": 53209.75,
        "low": 53165.1,
        "date": "2024-09-19T04:32:00.000Z",
        "date2": "2024-09-19 10:02"
    },
    {
        "open": 53197.85,
        "close": 53159,
        "high": 53210.15,
        "low": 53159,
        "date": "2024-09-19T04:33:00.000Z",
        "date2": "2024-09-19 10:03"
    },
    {
        "open": 53166.15,
        "close": 53160,
        "high": 53175,
        "low": 53143.9,
        "date": "2024-09-19T04:34:00.000Z",
        "date2": "2024-09-19 10:04"
    },
    {
        "open": 53154.05,
        "close": 53151.05,
        "high": 53175,
        "low": 53151.05,
        "date": "2024-09-19T04:35:00.000Z",
        "date2": "2024-09-19 10:05"
    },
    {
        "open": 53153.95,
        "close": 53125,
        "high": 53189.9,
        "low": 53125,
        "date": "2024-09-19T04:36:00.000Z",
        "date2": "2024-09-19 10:06"
    },
    {
        "open": 53126.8,
        "close": 53101.6,
        "high": 53137.65,
        "low": 53092,
        "date": "2024-09-19T04:37:00.000Z",
        "date2": "2024-09-19 10:07"
    },
    {
        "open": 53098.65,
        "close": 53113.75,
        "high": 53125,
        "low": 53085,
        "date": "2024-09-19T04:38:00.000Z",
        "date2": "2024-09-19 10:08"
    },
    {
        "open": 53111.05,
        "close": 53149.85,
        "high": 53158.7,
        "low": 53111.05,
        "date": "2024-09-19T04:39:00.000Z",
        "date2": "2024-09-19 10:09"
    },
    {
        "open": 53152.85,
        "close": 53099.65,
        "high": 53152.85,
        "low": 53090.25,
        "date": "2024-09-19T04:40:00.000Z",
        "date2": "2024-09-19 10:10"
    },
    {
        "open": 53092.05,
        "close": 53083.05,
        "high": 53115,
        "low": 53075,
        "date": "2024-09-19T04:41:00.000Z",
        "date2": "2024-09-19 10:11"
    },
    {
        "open": 53092.45,
        "close": 53115,
        "high": 53115,
        "low": 53085.55,
        "date": "2024-09-19T04:42:00.000Z",
        "date2": "2024-09-19 10:12"
    },
    {
        "open": 53120,
        "close": 53121.9,
        "high": 53122.95,
        "low": 53092.05,
        "date": "2024-09-19T04:43:00.000Z",
        "date2": "2024-09-19 10:13"
    },
    {
        "open": 53119,
        "close": 53085,
        "high": 53120.6,
        "low": 53085,
        "date": "2024-09-19T04:44:00.000Z",
        "date2": "2024-09-19 10:14"
    },
    {
        "open": 53085.1,
        "close": 53084.45,
        "high": 53099.95,
        "low": 53040.75,
        "date": "2024-09-19T04:45:00.000Z",
        "date2": "2024-09-19 10:15"
    },
    {
        "open": 53090.65,
        "close": 53091.35,
        "high": 53100,
        "low": 53073.55,
        "date": "2024-09-19T04:46:00.000Z",
        "date2": "2024-09-19 10:16"
    },
    {
        "open": 53097.55,
        "close": 53134.25,
        "high": 53134.25,
        "low": 53090,
        "date": "2024-09-19T04:47:00.000Z",
        "date2": "2024-09-19 10:17"
    },
    {
        "open": 53133.55,
        "close": 53078.15,
        "high": 53133.7,
        "low": 53078.15,
        "date": "2024-09-19T04:48:00.000Z",
        "date2": "2024-09-19 10:18"
    },
    {
        "open": 53090,
        "close": 53077.9,
        "high": 53093.45,
        "low": 53062.7,
        "date": "2024-09-19T04:49:00.000Z",
        "date2": "2024-09-19 10:19"
    },
    {
        "open": 53076.95,
        "close": 53107.4,
        "high": 53118.6,
        "low": 53076.95,
        "date": "2024-09-19T04:50:00.000Z",
        "date2": "2024-09-19 10:20"
    },
    {
        "open": 53110,
        "close": 53118.95,
        "high": 53118.95,
        "low": 53100,
        "date": "2024-09-19T04:51:00.000Z",
        "date2": "2024-09-19 10:21"
    },
    {
        "open": 53111.9,
        "close": 53123,
        "high": 53125,
        "low": 53101,
        "date": "2024-09-19T04:52:00.000Z",
        "date2": "2024-09-19 10:22"
    },
    {
        "open": 53116.55,
        "close": 53100,
        "high": 53130,
        "low": 53100,
        "date": "2024-09-19T04:53:00.000Z",
        "date2": "2024-09-19 10:23"
    },
    {
        "open": 53110,
        "close": 53127.75,
        "high": 53136,
        "low": 53090.15,
        "date": "2024-09-19T04:54:00.000Z",
        "date2": "2024-09-19 10:24"
    },
    {
        "open": 53133.7,
        "close": 53120.35,
        "high": 53136.9,
        "low": 53101.25,
        "date": "2024-09-19T04:55:00.000Z",
        "date2": "2024-09-19 10:25"
    },
    {
        "open": 53120.8,
        "close": 53126.15,
        "high": 53135.5,
        "low": 53117.3,
        "date": "2024-09-19T04:56:00.000Z",
        "date2": "2024-09-19 10:26"
    },
    {
        "open": 53122,
        "close": 53128.65,
        "high": 53138,
        "low": 53117.35,
        "date": "2024-09-19T04:57:00.000Z",
        "date2": "2024-09-19 10:27"
    },
    {
        "open": 53125.1,
        "close": 53124.35,
        "high": 53133.35,
        "low": 53100,
        "date": "2024-09-19T04:58:00.000Z",
        "date2": "2024-09-19 10:28"
    },
    {
        "open": 53130,
        "close": 53111.65,
        "high": 53131,
        "low": 53098.25,
        "date": "2024-09-19T04:59:00.000Z",
        "date2": "2024-09-19 10:29"
    },
    {
        "open": 53111.2,
        "close": 53118.9,
        "high": 53124.3,
        "low": 53106,
        "date": "2024-09-19T05:00:00.000Z",
        "date2": "2024-09-19 10:30"
    },
    {
        "open": 53115.15,
        "close": 53110,
        "high": 53133.8,
        "low": 53110,
        "date": "2024-09-19T05:01:00.000Z",
        "date2": "2024-09-19 10:31"
    },
    {
        "open": 53105.1,
        "close": 53148.55,
        "high": 53158,
        "low": 53101,
        "date": "2024-09-19T05:02:00.000Z",
        "date2": "2024-09-19 10:32"
    },
    {
        "open": 53145.1,
        "close": 53168,
        "high": 53169.9,
        "low": 53145.1,
        "date": "2024-09-19T05:03:00.000Z",
        "date2": "2024-09-19 10:33"
    },
    {
        "open": 53165.15,
        "close": 53153.1,
        "high": 53173,
        "low": 53151.8,
        "date": "2024-09-19T05:04:00.000Z",
        "date2": "2024-09-19 10:34"
    },
    {
        "open": 53151,
        "close": 53166,
        "high": 53169.9,
        "low": 53142.1,
        "date": "2024-09-19T05:05:00.000Z",
        "date2": "2024-09-19 10:35"
    },
    {
        "open": 53159.65,
        "close": 53160,
        "high": 53165,
        "low": 53146.2,
        "date": "2024-09-19T05:06:00.000Z",
        "date2": "2024-09-19 10:36"
    },
    {
        "open": 53148.3,
        "close": 53169,
        "high": 53169,
        "low": 53135.4,
        "date": "2024-09-19T05:07:00.000Z",
        "date2": "2024-09-19 10:37"
    },
    {
        "open": 53170,
        "close": 53159.9,
        "high": 53170,
        "low": 53142.05,
        "date": "2024-09-19T05:08:00.000Z",
        "date2": "2024-09-19 10:38"
    },
    {
        "open": 53154.3,
        "close": 53172.95,
        "high": 53172.95,
        "low": 53152.45,
        "date": "2024-09-19T05:09:00.000Z",
        "date2": "2024-09-19 10:39"
    },
    {
        "open": 53168.85,
        "close": 53150,
        "high": 53168.85,
        "low": 53150,
        "date": "2024-09-19T05:10:00.000Z",
        "date2": "2024-09-19 10:40"
    },
    {
        "open": 53150.2,
        "close": 53115.1,
        "high": 53150.2,
        "low": 53115.1,
        "date": "2024-09-19T05:11:00.000Z",
        "date2": "2024-09-19 10:41"
    },
    {
        "open": 53112.1,
        "close": 53131.1,
        "high": 53133.6,
        "low": 53100.1,
        "date": "2024-09-19T05:12:00.000Z",
        "date2": "2024-09-19 10:42"
    },
    {
        "open": 53121.9,
        "close": 53106.05,
        "high": 53135,
        "low": 53106,
        "date": "2024-09-19T05:13:00.000Z",
        "date2": "2024-09-19 10:43"
    },
    {
        "open": 53104.95,
        "close": 53114.95,
        "high": 53114.95,
        "low": 53104.95,
        "date": "2024-09-19T05:14:00.000Z",
        "date2": "2024-09-19 10:44"
    },
    {
        "open": 53119.9,
        "close": 53120.05,
        "high": 53120.05,
        "low": 53105.15,
        "date": "2024-09-19T05:15:00.000Z",
        "date2": "2024-09-19 10:45"
    },
    {
        "open": 53119.05,
        "close": 53146.9,
        "high": 53146.9,
        "low": 53118.3,
        "date": "2024-09-19T05:16:00.000Z",
        "date2": "2024-09-19 10:46"
    },
    {
        "open": 53146.25,
        "close": 53131.55,
        "high": 53148.95,
        "low": 53114.2,
        "date": "2024-09-19T05:17:00.000Z",
        "date2": "2024-09-19 10:47"
    },
    {
        "open": 53138.8,
        "close": 53146.6,
        "high": 53146.6,
        "low": 53125.95,
        "date": "2024-09-19T05:18:00.000Z",
        "date2": "2024-09-19 10:48"
    },
    {
        "open": 53142.75,
        "close": 53160,
        "high": 53165.1,
        "low": 53133.8,
        "date": "2024-09-19T05:19:00.000Z",
        "date2": "2024-09-19 10:49"
    },
    {
        "open": 53157,
        "close": 53162.05,
        "high": 53162.05,
        "low": 53139.9,
        "date": "2024-09-19T05:20:00.000Z",
        "date2": "2024-09-19 10:50"
    },
    {
        "open": 53162.35,
        "close": 53179,
        "high": 53179,
        "low": 53158.2,
        "date": "2024-09-19T05:21:00.000Z",
        "date2": "2024-09-19 10:51"
    },
    {
        "open": 53178.95,
        "close": 53201.85,
        "high": 53202.75,
        "low": 53165.55,
        "date": "2024-09-19T05:22:00.000Z",
        "date2": "2024-09-19 10:52"
    },
    {
        "open": 53200,
        "close": 53241.05,
        "high": 53244.8,
        "low": 53188.4,
        "date": "2024-09-19T05:23:00.000Z",
        "date2": "2024-09-19 10:53"
    },
    {
        "open": 53241.1,
        "close": 53236,
        "high": 53260,
        "low": 53230.8,
        "date": "2024-09-19T05:24:00.000Z",
        "date2": "2024-09-19 10:54"
    },
    {
        "open": 53237.2,
        "close": 53191.15,
        "high": 53237.2,
        "low": 53189.4,
        "date": "2024-09-19T05:25:00.000Z",
        "date2": "2024-09-19 10:55"
    },
    {
        "open": 53199.95,
        "close": 53194,
        "high": 53203.95,
        "low": 53193,
        "date": "2024-09-19T05:26:00.000Z",
        "date2": "2024-09-19 10:56"
    },
    {
        "open": 53195,
        "close": 53207,
        "high": 53215.2,
        "low": 53195,
        "date": "2024-09-19T05:27:00.000Z",
        "date2": "2024-09-19 10:57"
    },
    {
        "open": 53218.35,
        "close": 53231.95,
        "high": 53231.95,
        "low": 53210.45,
        "date": "2024-09-19T05:28:00.000Z",
        "date2": "2024-09-19 10:58"
    },
    {
        "open": 53232.25,
        "close": 53220,
        "high": 53232.25,
        "low": 53204.15,
        "date": "2024-09-19T05:29:00.000Z",
        "date2": "2024-09-19 10:59"
    },
    {
        "open": 53211,
        "close": 53228.15,
        "high": 53233,
        "low": 53210.05,
        "date": "2024-09-19T05:30:00.000Z",
        "date2": "2024-09-19 11:00"
    },
    {
        "open": 53227.15,
        "close": 53220.05,
        "high": 53228.15,
        "low": 53219.5,
        "date": "2024-09-19T05:31:00.000Z",
        "date2": "2024-09-19 11:01"
    },
    {
        "open": 53220,
        "close": 53227.9,
        "high": 53233,
        "low": 53210.05,
        "date": "2024-09-19T05:32:00.000Z",
        "date2": "2024-09-19 11:02"
    },
    {
        "open": 53228.35,
        "close": 53228.35,
        "high": 53235,
        "low": 53220,
        "date": "2024-09-19T05:33:00.000Z",
        "date2": "2024-09-19 11:03"
    },
    {
        "open": 53232.5,
        "close": 53195.45,
        "high": 53232.5,
        "low": 53190.1,
        "date": "2024-09-19T05:34:00.000Z",
        "date2": "2024-09-19 11:04"
    },
    {
        "open": 53193.45,
        "close": 53196.95,
        "high": 53215,
        "low": 53190,
        "date": "2024-09-19T05:35:00.000Z",
        "date2": "2024-09-19 11:05"
    },
    {
        "open": 53195,
        "close": 53184.7,
        "high": 53200,
        "low": 53170.6,
        "date": "2024-09-19T05:36:00.000Z",
        "date2": "2024-09-19 11:06"
    },
    {
        "open": 53184,
        "close": 53189.15,
        "high": 53196.95,
        "low": 53175.8,
        "date": "2024-09-19T05:37:00.000Z",
        "date2": "2024-09-19 11:07"
    },
    {
        "open": 53191,
        "close": 53191,
        "high": 53208,
        "low": 53190.25,
        "date": "2024-09-19T05:38:00.000Z",
        "date2": "2024-09-19 11:08"
    },
    {
        "open": 53191.1,
        "close": 53195,
        "high": 53200,
        "low": 53189,
        "date": "2024-09-19T05:39:00.000Z",
        "date2": "2024-09-19 11:09"
    },
    {
        "open": 53191,
        "close": 53198.85,
        "high": 53200.05,
        "low": 53184.5,
        "date": "2024-09-19T05:40:00.000Z",
        "date2": "2024-09-19 11:10"
    },
    {
        "open": 53203.6,
        "close": 53199,
        "high": 53203.6,
        "low": 53180.2,
        "date": "2024-09-19T05:41:00.000Z",
        "date2": "2024-09-19 11:11"
    },
    {
        "open": 53197.45,
        "close": 53201.1,
        "high": 53206.1,
        "low": 53193.05,
        "date": "2024-09-19T05:42:00.000Z",
        "date2": "2024-09-19 11:12"
    },
    {
        "open": 53190.5,
        "close": 53182.55,
        "high": 53190.5,
        "low": 53167.2,
        "date": "2024-09-19T05:43:00.000Z",
        "date2": "2024-09-19 11:13"
    },
    {
        "open": 53184.45,
        "close": 53199.9,
        "high": 53200.95,
        "low": 53182.2,
        "date": "2024-09-19T05:44:00.000Z",
        "date2": "2024-09-19 11:14"
    },
    {
        "open": 53200,
        "close": 53190,
        "high": 53210,
        "low": 53190,
        "date": "2024-09-19T05:45:00.000Z",
        "date2": "2024-09-19 11:15"
    },
    {
        "open": 53185.25,
        "close": 53194.95,
        "high": 53208.95,
        "low": 53185.25,
        "date": "2024-09-19T05:46:00.000Z",
        "date2": "2024-09-19 11:16"
    },
    {
        "open": 53194.85,
        "close": 53182.1,
        "high": 53202.8,
        "low": 53170.05,
        "date": "2024-09-19T05:47:00.000Z",
        "date2": "2024-09-19 11:17"
    },
    {
        "open": 53190,
        "close": 53166.25,
        "high": 53190.05,
        "low": 53166.25,
        "date": "2024-09-19T05:48:00.000Z",
        "date2": "2024-09-19 11:18"
    },
    {
        "open": 53170,
        "close": 53166.35,
        "high": 53180,
        "low": 53160.5,
        "date": "2024-09-19T05:49:00.000Z",
        "date2": "2024-09-19 11:19"
    },
    {
        "open": 53166,
        "close": 53161,
        "high": 53176.2,
        "low": 53161,
        "date": "2024-09-19T05:50:00.000Z",
        "date2": "2024-09-19 11:20"
    },
    {
        "open": 53160,
        "close": 53150.05,
        "high": 53167.7,
        "low": 53144.85,
        "date": "2024-09-19T05:51:00.000Z",
        "date2": "2024-09-19 11:21"
    },
    {
        "open": 53148.85,
        "close": 53080.25,
        "high": 53156.15,
        "low": 53068.4,
        "date": "2024-09-19T05:52:00.000Z",
        "date2": "2024-09-19 11:22"
    },
    {
        "open": 53080.2,
        "close": 53073.05,
        "high": 53099.75,
        "low": 53070.6,
        "date": "2024-09-19T05:53:00.000Z",
        "date2": "2024-09-19 11:23"
    },
    {
        "open": 53071.05,
        "close": 53079.35,
        "high": 53082,
        "low": 53057.2,
        "date": "2024-09-19T05:54:00.000Z",
        "date2": "2024-09-19 11:24"
    },
    {
        "open": 53080,
        "close": 53059.95,
        "high": 53082,
        "low": 53053,
        "date": "2024-09-19T05:55:00.000Z",
        "date2": "2024-09-19 11:25"
    },
    {
        "open": 53060,
        "close": 53075.4,
        "high": 53080,
        "low": 53054.05,
        "date": "2024-09-19T05:56:00.000Z",
        "date2": "2024-09-19 11:26"
    },
    {
        "open": 53072.75,
        "close": 53029.75,
        "high": 53072.75,
        "low": 52952.75,
        "date": "2024-09-19T05:57:00.000Z",
        "date2": "2024-09-19 11:27"
    },
    {
        "open": 53025.05,
        "close": 52952.65,
        "high": 53040,
        "low": 52943.15,
        "date": "2024-09-19T05:58:00.000Z",
        "date2": "2024-09-19 11:28"
    },
    {
        "open": 52969.9,
        "close": 52963.85,
        "high": 52970,
        "low": 52912.65,
        "date": "2024-09-19T05:59:00.000Z",
        "date2": "2024-09-19 11:29"
    },
    {
        "open": 52960,
        "close": 52971.9,
        "high": 52989.95,
        "low": 52932.4,
        "date": "2024-09-19T06:00:00.000Z",
        "date2": "2024-09-19 11:30"
    },
    {
        "open": 52978,
        "close": 53028,
        "high": 53028.7,
        "low": 52965.05,
        "date": "2024-09-19T06:01:00.000Z",
        "date2": "2024-09-19 11:31"
    },
    {
        "open": 53019.45,
        "close": 53010,
        "high": 53037.95,
        "low": 53000,
        "date": "2024-09-19T06:02:00.000Z",
        "date2": "2024-09-19 11:32"
    },
    {
        "open": 53010.05,
        "close": 52989.25,
        "high": 53012.55,
        "low": 52978.3,
        "date": "2024-09-19T06:03:00.000Z",
        "date2": "2024-09-19 11:33"
    },
    {
        "open": 52984.75,
        "close": 52976.15,
        "high": 52998.9,
        "low": 52970,
        "date": "2024-09-19T06:04:00.000Z",
        "date2": "2024-09-19 11:34"
    },
    {
        "open": 52987.05,
        "close": 52980,
        "high": 53013.5,
        "low": 52971.1,
        "date": "2024-09-19T06:05:00.000Z",
        "date2": "2024-09-19 11:35"
    },
    {
        "open": 52981.35,
        "close": 52988.55,
        "high": 53000,
        "low": 52977.1,
        "date": "2024-09-19T06:06:00.000Z",
        "date2": "2024-09-19 11:36"
    },
    {
        "open": 52998.65,
        "close": 52967.15,
        "high": 52999.6,
        "low": 52960.9,
        "date": "2024-09-19T06:07:00.000Z",
        "date2": "2024-09-19 11:37"
    },
    {
        "open": 52959,
        "close": 52937.85,
        "high": 52970.45,
        "low": 52923.95,
        "date": "2024-09-19T06:08:00.000Z",
        "date2": "2024-09-19 11:38"
    },
    {
        "open": 52943.1,
        "close": 52952,
        "high": 52967.15,
        "low": 52927.95,
        "date": "2024-09-19T06:09:00.000Z",
        "date2": "2024-09-19 11:39"
    },
    {
        "open": 52950.05,
        "close": 52937.55,
        "high": 52962.95,
        "low": 52924.15,
        "date": "2024-09-19T06:10:00.000Z",
        "date2": "2024-09-19 11:40"
    },
    {
        "open": 52935.25,
        "close": 52882.7,
        "high": 52935.25,
        "low": 52875,
        "date": "2024-09-19T06:11:00.000Z",
        "date2": "2024-09-19 11:41"
    },
    {
        "open": 52880,
        "close": 52860.9,
        "high": 52892.6,
        "low": 52836.65,
        "date": "2024-09-19T06:12:00.000Z",
        "date2": "2024-09-19 11:42"
    },
    {
        "open": 52860.4,
        "close": 52878,
        "high": 52880.7,
        "low": 52850,
        "date": "2024-09-19T06:13:00.000Z",
        "date2": "2024-09-19 11:43"
    },
    {
        "open": 52885,
        "close": 52933.65,
        "high": 52939.95,
        "low": 52878.2,
        "date": "2024-09-19T06:14:00.000Z",
        "date2": "2024-09-19 11:44"
    },
    {
        "open": 52932.35,
        "close": 52954.75,
        "high": 52960.3,
        "low": 52910.2,
        "date": "2024-09-19T06:15:00.000Z",
        "date2": "2024-09-19 11:45"
    },
    {
        "open": 52943.65,
        "close": 52926.8,
        "high": 52952.55,
        "low": 52915.9,
        "date": "2024-09-19T06:16:00.000Z",
        "date2": "2024-09-19 11:46"
    },
    {
        "open": 52922.3,
        "close": 52950,
        "high": 52950,
        "low": 52910.05,
        "date": "2024-09-19T06:17:00.000Z",
        "date2": "2024-09-19 11:47"
    },
    {
        "open": 52949.95,
        "close": 52934.4,
        "high": 52950,
        "low": 52934.4,
        "date": "2024-09-19T06:18:00.000Z",
        "date2": "2024-09-19 11:48"
    },
    {
        "open": 52931,
        "close": 52925.65,
        "high": 52931,
        "low": 52907.15,
        "date": "2024-09-19T06:19:00.000Z",
        "date2": "2024-09-19 11:49"
    },
    {
        "open": 52923,
        "close": 52935.55,
        "high": 52940,
        "low": 52902.55,
        "date": "2024-09-19T06:20:00.000Z",
        "date2": "2024-09-19 11:50"
    },
    {
        "open": 52937.95,
        "close": 52971.9,
        "high": 52976,
        "low": 52937.95,
        "date": "2024-09-19T06:21:00.000Z",
        "date2": "2024-09-19 11:51"
    },
    {
        "open": 52974.8,
        "close": 52985,
        "high": 52985,
        "low": 52958.55,
        "date": "2024-09-19T06:22:00.000Z",
        "date2": "2024-09-19 11:52"
    },
    {
        "open": 52980,
        "close": 52973.45,
        "high": 52988.85,
        "low": 52964.45,
        "date": "2024-09-19T06:23:00.000Z",
        "date2": "2024-09-19 11:53"
    },
    {
        "open": 52973.2,
        "close": 52996.65,
        "high": 53009.95,
        "low": 52960,
        "date": "2024-09-19T06:24:00.000Z",
        "date2": "2024-09-19 11:54"
    },
    {
        "open": 53010,
        "close": 53049.95,
        "high": 53050,
        "low": 52997.35,
        "date": "2024-09-19T06:25:00.000Z",
        "date2": "2024-09-19 11:55"
    },
    {
        "open": 53049.7,
        "close": 53027.4,
        "high": 53049.7,
        "low": 53020,
        "date": "2024-09-19T06:26:00.000Z",
        "date2": "2024-09-19 11:56"
    },
    {
        "open": 53028.55,
        "close": 53019.75,
        "high": 53039.65,
        "low": 53008.35,
        "date": "2024-09-19T06:27:00.000Z",
        "date2": "2024-09-19 11:57"
    },
    {
        "open": 53016.8,
        "close": 52993.8,
        "high": 53022.05,
        "low": 52983.2,
        "date": "2024-09-19T06:28:00.000Z",
        "date2": "2024-09-19 11:58"
    },
    {
        "open": 52983.2,
        "close": 52950,
        "high": 52989.15,
        "low": 52944.75,
        "date": "2024-09-19T06:29:00.000Z",
        "date2": "2024-09-19 11:59"
    },
    {
        "open": 52947.1,
        "close": 52981.1,
        "high": 52983.95,
        "low": 52940.4,
        "date": "2024-09-19T06:30:00.000Z",
        "date2": "2024-09-19 12:00"
    },
    {
        "open": 52979.85,
        "close": 53001.05,
        "high": 53009.3,
        "low": 52977.6,
        "date": "2024-09-19T06:31:00.000Z",
        "date2": "2024-09-19 12:01"
    },
    {
        "open": 53000,
        "close": 53024.15,
        "high": 53029.65,
        "low": 52990,
        "date": "2024-09-19T06:32:00.000Z",
        "date2": "2024-09-19 12:02"
    },
    {
        "open": 53015.95,
        "close": 53046.6,
        "high": 53049,
        "low": 53014.95,
        "date": "2024-09-19T06:33:00.000Z",
        "date2": "2024-09-19 12:03"
    },
    {
        "open": 53033.75,
        "close": 53040,
        "high": 53049,
        "low": 53022.4,
        "date": "2024-09-19T06:34:00.000Z",
        "date2": "2024-09-19 12:04"
    },
    {
        "open": 53027.05,
        "close": 53025.75,
        "high": 53037.95,
        "low": 53015.85,
        "date": "2024-09-19T06:35:00.000Z",
        "date2": "2024-09-19 12:05"
    },
    {
        "open": 53035,
        "close": 53023.6,
        "high": 53035,
        "low": 53014.85,
        "date": "2024-09-19T06:36:00.000Z",
        "date2": "2024-09-19 12:06"
    },
    {
        "open": 53020.2,
        "close": 53025,
        "high": 53025,
        "low": 53002.5,
        "date": "2024-09-19T06:37:00.000Z",
        "date2": "2024-09-19 12:07"
    },
    {
        "open": 53024.85,
        "close": 53024.2,
        "high": 53034.85,
        "low": 53011.9,
        "date": "2024-09-19T06:38:00.000Z",
        "date2": "2024-09-19 12:08"
    },
    {
        "open": 53016.15,
        "close": 53046.55,
        "high": 53050,
        "low": 53016.05,
        "date": "2024-09-19T06:39:00.000Z",
        "date2": "2024-09-19 12:09"
    },
    {
        "open": 53040.1,
        "close": 53023.6,
        "high": 53056.75,
        "low": 53020,
        "date": "2024-09-19T06:40:00.000Z",
        "date2": "2024-09-19 12:10"
    },
    {
        "open": 53025.15,
        "close": 53027.95,
        "high": 53036.4,
        "low": 53009.05,
        "date": "2024-09-19T06:41:00.000Z",
        "date2": "2024-09-19 12:11"
    },
    {
        "open": 53026.05,
        "close": 53068,
        "high": 53075,
        "low": 53026.05,
        "date": "2024-09-19T06:42:00.000Z",
        "date2": "2024-09-19 12:12"
    },
    {
        "open": 53062.75,
        "close": 53043.55,
        "high": 53065.6,
        "low": 53036.65,
        "date": "2024-09-19T06:43:00.000Z",
        "date2": "2024-09-19 12:13"
    },
    {
        "open": 53037.8,
        "close": 53026.35,
        "high": 53050.4,
        "low": 53024.45,
        "date": "2024-09-19T06:44:00.000Z",
        "date2": "2024-09-19 12:14"
    },
    {
        "open": 53024.5,
        "close": 53020,
        "high": 53039.1,
        "low": 53014.05,
        "date": "2024-09-19T06:45:00.000Z",
        "date2": "2024-09-19 12:15"
    },
    {
        "open": 53017.8,
        "close": 53019.65,
        "high": 53024,
        "low": 53002.4,
        "date": "2024-09-19T06:46:00.000Z",
        "date2": "2024-09-19 12:16"
    },
    {
        "open": 53010,
        "close": 53035,
        "high": 53040,
        "low": 53010,
        "date": "2024-09-19T06:47:00.000Z",
        "date2": "2024-09-19 12:17"
    },
    {
        "open": 53031.6,
        "close": 53040.6,
        "high": 53049.95,
        "low": 53031.6,
        "date": "2024-09-19T06:48:00.000Z",
        "date2": "2024-09-19 12:18"
    }
]
    // Format the data for the chart
    const formattedData = data.map(item => ({
        x: new Date(item.date).getTime(),
        y: [item.open, item.high, item.low, item.close]
    }));

    // Set initial chart options and data
    const [chartData, setChartData] = useState({
        series: [{ data: formattedData }],
        options: {
            chart: {
                type: 'candlestick',
                height: '700',
                zoom: {
                    enabled: true, // Enable zooming
                    type: 'x',    // Zoom in the x-axis direction
                    resetIcon: {
                        shape: 'arrow',
                        fillColor: '#fff',
                        strokeColor: '#37474f'
                    }
                }
            },
            title: {
                text: 'Candlestick Chart with Zoom',
                align: 'left'
            },
            xaxis: {
                type: 'datetime',
                min: marketStart,
                max: marketEnd,
                tickAmount: 10,
                labels: {
                    format: 'HH:mm'
                }
            },
            yaxis: {
                tooltip: {
                    enabled: true
                }
            }
        }
    });

    return (
        <div id="chart">
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="candlestick"
                height={350}
            />
        </div>
    );
};

export default ApexChart;
